
<template>
    <main class="J-parallax">
        <!-- <section id="hero">
            <h1>Un StarUp de <br> emprendimiento <br> social tecnológico.</h1>
            <p>Goot</p>
            <img src="../assets/creations/Group1.png" alt="">
        </section>
        <div class="imagen">
            <img src="../assets/creations/Sun1.svg" alt="">
        </div>
        <div class="imagen2">
            <img src="../assets/creations/Vector.jpg" alt="">
        </div> -->
        <!-- <div class="J-parallax-img" ref="hill1" alt=""></div> -->
        <div class="sunMoon">
            <img src="../assets/creations/Sun1.svg" alt="">
        </div>
        <img class="J-img" src="/img/day/hill1.svg" ref="hill1" alt="">
        <img class="J-img" src="/img/day/hill2.png" ref="hill2" alt="">
        <img class="J-img" src="/img/day/hill3.png" ref="hill3" alt="">
        <img class="J-img" src="/img/day/hill4.png" ref="hill4" alt="">
        <img class="J-img" src="/img/day/hill5.png" ref="hill5" alt="">
        <img class="J-img" src="/img/day/tree.png" ref="tree" alt="">
        
        <h2 ref="title" class="J-parallax-title" style="font-size:50px">Gootwork</h2>
        <img class="J-img" src="/img/day/leaf.png" ref="leaf" alt="">
        <img class="J-img" src="/img/day/plant.png" ref="plant" alt="">
        
    </main>
</template>

<script>
import { ref } from 'vue'

export default {
    setup() {
        const title= ref(null);
        const leaf= ref(null);
        const hill1= ref(null);
        const hill2= ref(null);
        const hill3= ref(null);
        const hill4= ref(null);
        const hill5= ref(null);
        // ...
        return {
            title,
            leaf ,
            hill1,
            hill2,
            hill3,
            hill4,
            hill5,
        }
    },
    data: () => ({
    }),
    mounted() {
        window.addEventListener('scroll',(e)=>{
            let value=window.scrollY;
            if(window.screen.height>=value){
                this.title.style.marginTop=value*2.5+"px";
                this.leaf.style.top=value*-1.5+"px";
                this.leaf.style.left=value*1.5+"px";
                this.hill5.style.left=value*1.5+"px";
                this.hill4.style.left=value*-1.5+"px";
                this.hill1.style.top=value*1+"px";
            }
        })
    },
    methods: {
        
    }

}
</script>
        

<style scoped>
    .main {
        margin-top: 30px;
    }
    #hero {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        height: 90vh;
        background-image: url('../assets/creations/Weather.svg');
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: right 5% bottom 45%;
    }

    #hero h1 {
        position: absolute;
        left: 51.26%;
        right: 14.29%;
        top: 40.94%;
        bottom: 37.07%;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 72px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #000000;
    }

    #hero img {
        position: absolute;
        left: 8.2%;
        right: 63.24%;
        top: 40.94%;
        bottom: 12.59%;

    }

    #hero p {
        position: absolute;
        left: 45.3%;
        right: 4.03%;
        top: 7.33%;
        bottom: 60.18%;

        font-family: 'Permanent Marker';
        font-style: normal;
        font-weight: 400;
        font-size: 128px;
        line-height: 186px;
        display: flex;
        align-items: center;
        text-align: justify;

        color: #DB8100;

        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    }

    .imagen img {
        position: absolute;
        width: auto;
        left: 4.89%;
        right: 81.81%;
        top: 18.33%;
        bottom: 61.2%;

    }

    .imagen p {

        position: absolute;
        left: 70%;
        right: 4.03%;
        top: 7.33%;
        bottom: 60.18%;

        font-family: 'Permanent Marker';
        font-style: normal;
        font-weight: 400;
        font-size: 128px;
        line-height: 186px;
        display: flex;
        align-items: center;
        text-align: justify;

        color: #00588B;

        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .imagen2 img {
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        body {
            background-color: #fff;
        }

        #hero {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            height: 90vh;
        }

        #hero h1 {
            position: absolute;
            left: auto;
            top: auto;
            bottom: auto;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 32px;
            line-height: 72px;
            display: flex;
            align-items: center;
            text-align: center;

            color: #000000;
        }

        #hero img {
            position: absolute;
            width: 30%;
            left: 5%;
            right: 30%;
            top: 50%;
            bottom: 10%;
        }

        .imagen p {
            position: absolute;
            left: 63%;
            right: auto;
            top: auto;
            bottom: 60.18%;

            font-family: 'Permanent Marker';
            font-style: normal;
            font-weight: 400;
            font-size: 80px;
            line-height: 186px;
            display: flex;
            align-items: center;
            text-align: justify;

            color: #00588B;
            ;

            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        #hero p {
            position: absolute;
            left: auto;
            right: auto;
            top: auto;
            bottom: 60.18%;

            font-family: 'Permanent Marker';
            font-style: normal;
            font-weight: 400;
            font-size: 80px;
            line-height: 186px;
            display: flex;
            align-items: center;
            text-align: justify;

            color: #DB8100;

            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        }

        .imagen img {
            position: absolute;
            width: 25%;
            left: 2%;
            right: 2%;
            top: 10%;
            bottom: 30%;
        }
    }

    @media screen and (max-width: 600px) {
        body {
            background-color: #fff;
        }

        #hero {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            height: 90vh;
        }

        #hero h1 {
            position: absolute;
            top: 38%;
            bottom: auto;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 25px;
            line-height: 72px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
        }

        #hero img {
            position: absolute;
            width: 30%;
            left: 5%;
            right: 30%;
            top: 50%;
            bottom: 10%;
        }

        .imagen p {
            position: absolute;
            left: 63%;
            right: auto;
            top: auto;
            bottom: 60.18%;

            font-family: 'Permanent Marker';
            font-style: normal;
            font-weight: 400;
            font-size: 45px;
            line-height: 186px;
            display: flex;
            align-items: center;
            text-align: justify;

            color: #00588B;
            ;

            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        #hero p {
            position: absolute;
            left: auto;
            right: auto;
            top: auto;
            bottom: 60.18%;

            font-family: 'Permanent Marker';
            font-style: normal;
            font-weight: 400;
            font-size: 45px;
            line-height: 186px;
            display: flex;
            align-items: center;
            text-align: justify;

            color: #DB8100;

            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        }

        .imagen img {
            position: absolute;
            left: auto;
            right: auto;
            top: 20%;
            bottom: 0%;
        }
    }
</style>

